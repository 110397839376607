.App-header {
  background-color: #2c3531;
  font-size: calc(10px + 2vmin);
}

body{
    /* background-color: rgb(7, 7, 25); */
    background-image: linear-gradient( #2c3531 , #116566) !important;
    background-attachment: fixed;
  }
.App-link {
  color: #D1E8E2;
}
.card{
  margin-bottom: 15px;
}

.WebsiteName{
  color:#D1E8E2!important;
  font-size:20px!important;
  font-weight: bold;
  font-family: 'Courier New', Courier, monospace;

}

h1{
  color:#D1E8E2;
  font-size:45 !important;
  font-weight: bold ;
  font-family: 'Courier New', Courier, monospace;
  
}
.card-title.h5{
  color:#D1E8E2;
  font-family: 'Courier New', Courier, monospace;
}

li{
  color:#D1E8E2;
  font-size:16px;
  font-family: 'Courier New', Courier, monospace;
}
dt{
  color:#D1E8E2;
  font-family: 'Courier New', Courier, monospace;
}
dd{
color:#D1E8E2;
  font-family: 'Courier New', Courier, monospace;
}

.BodyFont{
  color:#D1E8E2 ;
  font-family: 'Courier New', Courier, monospace;
  font-size:20px;
}

.NavStaticBottom{
  display:flex;
  flex-wrap: wrap;
  align-items: flex-end;

}

.BodyFont.offcanvas-body{
  color:#D1E8E2 ;
  
}
.offcanvas-header{
  color:#D1E8E2;
  background-color: #2c3531;
}

.nav-link{
  color:#D1E8E2 !important;

}

.vstack{
  justify-content: flex-end;
}

.header-background{
  background-image: linear-gradient( #2c3531 , #116466) !important;
}
.offcanvas.offcanvas-start.show{
  background-image: linear-gradient( #2c3531 , #116466) !important;
}
.side-background{
  background-image: linear-gradient( #2c3531 , #116466) !important;
}

p{
  color:#D1E8E2;
  font-family: 'Courier New', Courier, monospace;
  font-size:16px;
}

.form-label{
  color:#D1E8E2;
  font-family: 'Courier New', Courier, monospace;
  font-size:20px;
}
.nav-item{
  color:#D1E8E2;
  font-family: 'Courier New', Courier, monospace !important;

}
.nav-link{
  color:#D1E8E2;
  font-family: 'Courier New', Courier, monospace !important;
}
.content-container{
  overflow-y: auto;
  height:  100%;
  position:relative;
}
.sidebar{
  overflow-y: auto;
  height: 100%;
  position:relative;
}
.content-row{
  overflow-y: auto;
  height:100vh;
  position:relative;
}
/* .topmenu{
  height: 3vh;
  position:relative;
} */
code {
  font-size: .875em !important;
  font-family: var(--bs-font-monospace) !important;
  color:inherit !important;
}